
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BannerComponent',
  props: {
    background: String,
    subTitle: String,
    title: String,
    titleName: String,
  },
});
