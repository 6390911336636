
  import { defineComponent } from 'vue';
  import BannerComponent from '@/components/BannerComponent.vue';
  import DefaultComponent from '@/components/DefaultComponent.vue';

  export default defineComponent({
    name: 'HomeView',
    components: {
      BannerComponent,
      DefaultComponent
    },
  });
