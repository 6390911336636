import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerComponent = _resolveComponent("BannerComponent")!
  const _component_DefaultComponent = _resolveComponent("DefaultComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BannerComponent, {
      background: "903-1920x1080.jpg",
      "sub-title": "Welcome",
      title: "This is the website of",
      "title-name": "Ruben Jansen"
    }),
    _createVNode(_component_DefaultComponent)
  ], 64))
}